@import "../reset.scss";

#home-contents {
  color: #000;

  .home-main {
    background: url("../image/home/home-main.png"), linear-gradient(0deg, rgba(241, 223, 219, 1) 0%, rgba(255, 254, 249, 1) 100%);
    //background-color: #fff4e7;
    //background: rgb(241, 223, 219);
    //background-image: url("../image/home/home-main-transparent.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    // background-color: #fff4e7;
    height: 350px;
    max-width: none;
    width: 100vw;
    margin-left: calc(50% - 50vw);
    padding: 0 5rem;

    h1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      font-family: $font_GlacialIndifference;
      letter-spacing: 0.2rem;
    }
  }

  .home-about {
    h2 {
      font-family: $font_Kollektif;
      letter-spacing: 0.7rem;
    }
    p {
      font-family: $font_Alata;
      font-size: $h4;
      padding: 1rem 5rem 0;
      margin-bottom: 3rem;
    }

    .btn {
      width: 200px;
      padding: 0.75rem 0;
      font-size: calc(0.8rem + 0.1vw);
      background-color: #000;
      color: #fff;
      font-family: $font_Kollektif;
      letter-spacing: 0.3rem;

      border-radius: 0;
      border-color: #000;
      transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    }

    .btn:hover{
      background-color: #4a4a4a;
      border-color: #4a4a4a;
    }

    .btn:active{
      background-color: #737373;
      border-color: #737373;
    }
  }

  .home-ourwork {
    h2 {
      font-family: $font_Kollektif;
      letter-spacing: 0.7rem;
    }
    > p {
      font-family: $font_Alata;
      font-size: $h4;
      padding: 1rem 5rem;
    }

    .home-ourwork-detail {
      h3 {
        font-family: $font_Kollektif;
        letter-spacing: 0.25rem;
      }

      p {
        font-family: $font_GlacialIndifference;
        text-align: left;
        font-size: $h4;
      }
    }

    .home-ourwork-card {
      .row {
        margin: 2rem 0;
        height: 350px;
      }

      .card-title {
        background-color: #fff4e7;
        flex: 0 0 auto;
        width: 350px;
      }

      .card-title,
      .col {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 3rem;
      }

      .card-alt {
        flex-direction: row-reverse;
      }
    }
  }

  > div {
    max-width: 1200px;
    margin: 0 auto 5rem;
    padding: 0 5rem;
  }

  @media screen and (max-width: 1199.98px) {
    /* 데스크탑 */
    .home-about {
      p {
        padding: 1rem 3rem;
      }
    }
    .home-ourwork {
      > p {
        padding: 1rem 3rem;
      }
      .home-ourwork-card {
        .row {
          height: 300px;
        }
        .card-title {
          width: 300px;
        }
      }

      .home-ourwork-detail {
        p {
          font-size: $h5;
        }
      }
    }
  }

  @media screen and (max-width: 991.98px) {
    /* 타블렛 */
    .home-ourwork {
      .home-ourwork-card {
        .row {
          flex-direction: column;
          flex-wrap: nowrap;
          height: unset;

          .col {
            padding: 1rem 3rem;
            height: 100rem;
          }
          .card-title {
            width: 100%;
            height: 5rem;

            h3 {
              margin: 0;
            }
          }
        }
        .card-title {
          width: 200px;
        }
      }
    }

    > div {
      margin: 0 1rem 5rem 1rem;
    }
  }

  @media screen and (max-width: 767.98px) {
    /* 스마트폰 가로 */
    .home-about {
      p {
        margin-bottom: 1rem;
      }
    }

    > div {
      margin: 0 0 5rem 0;
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 575.98px) {
    /* 스마트폰 세로 */
    h2 {
      font-size: $h3;
    }
    h3 {
      font-size: $h4;
    }
    h4 {
      font-size: $h5;
    }

    .home-main {
      padding: 0 2rem;
    }

    .home-about,
    .home-ourwork {
      p {
        font-size: $h5;
      }
    }

    .home-about {
      p {
        padding: 1rem 0;
      }
    }
    .home-ourwork {
      > p {
        padding: 1rem 0;
      }
    }
  }
}
