@import "../reset.scss";

#contact-contents {
  .contact-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 5rem auto 2rem;

    h2 {
      font-family: $font_Kollektif;
      letter-spacing: 0.7rem;
      margin-bottom: 2rem;
    }

    p {
      font-family: $font_Alata;
      font-size: $h4;
    }
  }

  .contact-form {
    font-family: $font_Alata;

    .intl-tel-input {
      width: 100%;
    }

    .btn {
      width: 200px;
      padding: 0.75rem 0;
      font-size: calc(0.8rem + 0.1vw);
      background-color: #000;
      font-family: $font_Kollektif;
      letter-spacing: 0.3rem;

      border-radius: 0;
      border-color: #000;

      transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    }

    .btn:hover {
      background-color: #4a4a4a;
      border-color: #4a4a4a;
    }

    .btn:active {
      background-color: #737373;
      border-color: #737373;
    }

    .hidden {
      display: none;
    }

    .contact-form-optional {
      font-size: 0.7rem;
      display: inline;
    }

    .recaptcha > div > div {
      margin: 1rem auto;
    }
  }

  .contact-office-address {
    font-family: $font_Alata;

    h5 {
      letter-spacing: 0.1rem;
    }

    p {
      white-space: pre-line;
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  }

  > div {
    max-width: 1200px;
    margin: 0 auto 5rem;
    padding: 0 5rem;
  }

  @media screen and (max-width: 1199.98px) {
    /* 데스크탑 */
  }

  @media screen and (max-width: 991.98px) {
    /* 타블렛 */
    > div {
      margin: 0 1rem 5rem 1rem;
    }
  }

  @media screen and (max-width: 767.98px) {
    /* 스마트폰 가로 */
    .contact-main {
      margin-top: 1rem;
    }

    > div {
      margin: 0 0 5rem 0;
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 575.98px) {
    /* 스마트폰 세로 */
    h2 {
      font-size: $h3;
    }
    h3 {
      font-size: $h4;
    }
    h4 {
      font-size: $h5;
    }

    .contact-main {
      p {
        font-size: $h5;
      }
    }
  }
}
