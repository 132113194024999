@import "../reset.scss";

#about-contents {
  .about-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 5rem auto 2rem;

    h2 {
      font-family: $font_Kollektif;
      letter-spacing: 0.7rem;
      margin-bottom: 2rem;
    }

    p {
      font-family: $font_Alata;
      font-size: $h4;
    }
  }

  .about-profile {
    display: flex;
    flex-direction: column;

    h4 {
      font-family: $font_Garet;
      font-weight: normal;
      letter-spacing: 0.7rem;
    }

    p, li {
      font-family: $font_GlacialIndifference;
    }

    .profile-image {
      width: 300px;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: center;
      margin: 0 2rem 0 auto;
    }

    .jennifer {
      background-image: url("../image/about/jennifer.jpg");
    }

    .chloe {
      background-image: url("../image/about/chloe.jpg");
    }

    .row {
      margin: 1rem 0 2rem;
      min-height: 400px;
    }

    .profile-detail {
      text-align: left;
      // padding-top: 2rem;

      .detail-biography {
        margin-bottom: 2rem;
      }

      .detail-text, .detail-education-text{
        white-space: pre-line;
        margin-bottom: 2rem;
      }

      .detail-education {
        text-decoration: underline;
      }

      .detail-education-list{
        text-align: left;

        p{
          margin: 0;
        }

        li{
          list-style: none;
        }

        .detail-education-list-career li{
          font-family: $font_GlacialIndifference;
        }
      }
    }
  }

  > div {
    max-width: 1200px;
    margin: 0 auto 5rem;
    padding: 0 5rem;
  }

  @media screen and (max-width: 1199.98px) {
    /* 데스크탑 */
  }

  @media screen and (max-width: 991.98px) {
    /* 타블렛 */

    > div {
      margin: 0 1rem 5rem 1rem;
    }
  }

  @media screen and (max-width: 767.98px) {
    .about-main{
      margin-top: 1rem;
    }
    /* 스마트폰 가로 */
    .about-profile {
      .row {
        flex-direction: column;
        padding: 0;

        .col {
          text-align: center;
          margin: 1rem auto;
          padding: 0;

          .profile-image {
            margin: 0 auto;
            width: 100%;
            max-width: 375px;
            height: 0;
            // padding-top: calc(min(500px, 600 / 500 * 100%));
          }

          .detail-education{
            text-align: left;
          }

          .detail-text, .detail-education-text{
            text-align: left;
          }
        }
      }

      .jennifer {
        padding-top: calc(min(375px, 100%));
      }

      .chloe {
        padding-top: calc(min(500px, 100%));
      }
    }

    > div {
      margin: 0 0 5rem 0;
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 575.98px) {
    /* 스마트폰 세로 */
    h2 {
      font-size: $h3;
    }
    h3 {
      font-size: $h4;
    }
    h4 {
      font-size: $h5;
    }

    .about-main {
      p {
        font-size: $h5;
      }
    }

    .about-profile {
      p, li {
        font-size: calc(0.75rem + 0.4vw);
      }
    }
  }
}
