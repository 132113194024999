@import "../reset.scss";

#footer-contents {
  background-color: #000;
  color: #fff;
  padding: 2rem 10rem;

  font-family: $font_Alata;
  letter-spacing: 0.1rem;

  .footer-nav {
    margin-bottom: 3rem;

    .footer-nav-item {
      display: block;
      text-align: left;
      margin: 1rem 0;
      padding: 0;
      color: #fff;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1199.98px) {
    /* 데스크탑 */
  }

  @media screen and (max-width: 991.98px) {
    /* 타블렛 */
    padding: 2rem 5rem;
  }

  @media screen and (max-width: 767.98px) {
    /* 스마트폰 가로 */
    padding: 2rem;

    .footer-nav-item,
    p {
      font-size: calc(0.75rem + 0.1vw);
    }

    .footer-nav {
      margin-bottom: 2rem;

      .footer-nav-item {
        margin: 0.8rem 0;
      }
    }
  }

  @media screen and (max-width: 575.98px) {
    /* 스마트폰 세로 */
  }
}
