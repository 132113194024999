@import "../reset.scss";

#navi-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header-logo {
    width: 250px;
    height: 250px;
    background-image: url("../image/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    cursor: pointer;
  }

  .navi-menu {
    width: 100%;

    .navbar {
      font-family: $font_Alata;
      letter-spacing: 0.1rem;
      background-color: #000;
      width: 100%;

      .navbar-nav {
        width: 100%;
        margin: 0 auto;

        align-items: center;
      }

      a {
        display: block;
        color: #fff;
        width: calc(100% / 3);
        padding: 8px;
      }
    }
  }

  .navi-hamburger-trigger {
    display: none;
    position: absolute;
    margin: 13px 15px;
    width: 40px;
    height: 34px;

    // top: 0.7rem;
    left: 0.7rem;
    cursor: pointer;

    span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 6px;
      background-color: #000;
      border-radius: 6px;
      display: inline-block;
      transition: all 0.4s ease-in-out;
      box-sizing: border-box;
    }

    span:nth-of-type(1) {
      top: 0;
    }

    span:nth-of-type(2) {
      top: 0.8rem;
    }

    span:nth-of-type(3) {
      top: 1.6rem;
    }
  }

  .navi-hamburger-trigger.active{
    z-index: 102;
  }
  
  .navi-hamburger-trigger.active span:nth-of-type(1) {
    -webkit-transform: translateY (calc(1.6rem / 2)) rotate (-45deg);
    transform: translateY(calc(1.6rem / 2)) rotate(-45deg);
  }

  .navi-hamburger-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }

  .navi-hamburger-trigger.active span:nth-of-type(3) {
    -webkit-transform: translateY(-calc(-1.6rem / 2)) rotate(45deg);
    transform: translateY(calc(-1.6rem / 2)) rotate(45deg);
  }

  .navi-menu-right-logo {
    margin: 2rem auto 1rem;
    width: 150px;
    height: 150px;
    background-image: url("../image/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    cursor: pointer;
  }

  .navi-menu-right {
    z-index: 101;
    position: fixed;
    top: 0;
    left: -320px;
    width: 320px;
    height: 100vh;
    background-color: #fff;
    transition: left 0.4s ease-in-out;

    .navi-menu-right-inner {
      .navi-menu-right-item {
        text-align: left;
        border-bottom: 1px solid #e6eaea;
        padding: 2rem 2rem;
        a {
          font-family: $font_Alata;
          letter-spacing: 0.2rem;
          font-size: $h5;
          color: #000;
          padding: 8px;
        }
      }
    }
  }

  .navi-menu-right.active {
    left: 0;
  }

  .navi-menu-right-blind {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.4s ease-in-out;
    pointer-events: none;
  }

  .navi-menu-right-blind.active {
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
    pointer-events: auto;
  }

  @media screen and (max-width: 1199.98px) {
    /* 데스크탑 */
  }

  @media screen and (max-width: 991.98px) {
    /* 타블렛 */
  }

  @media screen and (max-width: 767.98px) {
    /* 스마트폰 가로 */
    a {
      font-size: calc(0.75rem + 0.1vw);
    }

    .header-logo{
      width: 200px;
      height: 200px;
    }

    .navi-menu {
      .navbar {
        display: none;
      }
    }

    .navi-hamburger-trigger {
      display: block;
    }
  }

  @media screen and (max-width: 575.98px) {
    /* 스마트폰 세로 */
  }
}
