@import "../reset.scss";

#dropdown-language {
  text-align: left;

  .language-item {
    padding: 0;
    margin: 1rem 0;
    color: #fff;

    text-align: left;
    font-family: $font_Alata;
    letter-spacing: 0.2rem;
    display: none;
    cursor: pointer;
  }

  .show {
    display: block;
  }

  @media screen and (max-width: 1199.98px) {
    /* 데스크탑 */
  }

  @media screen and (max-width: 991.98px) {
    /* 타블렛 */
  }

  @media screen and (max-width: 767.98px) {
    /* 스마트폰 가로 */
    .language-item{
      margin: 0.8rem 0;
      font-size: calc(0.75rem + 0.1vw);
    }
  }

  @media screen and (max-width: 575.98px) {
    /* 스마트폰 세로 */
  }
}
