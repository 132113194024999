@import "../reset.scss";

#privacy-contents {
  .privacy-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 5rem auto 2rem;

    h2 {
        font-family: $font_Kollektif;
        letter-spacing: 0.7rem;
        margin-bottom: 2rem;
    }

    p{
        margin-bottom: 1.5rem;
        text-align: left;
    }

    .privacy-intro{
        width: 100%;
    }

    .privacy-main-text{
        width: 100%;
        .sub-title{
            font-weight: bold;
        }

        ul{
            padding: unset;
            margin: unset;

            li{
                margin-bottom: 1.5rem;
                font-family: $font_GlacialIndifference;
                text-align: left;
                list-style-type: decimal;
                list-style-position: inside;
            }
        }
    }
    

    p {
        font-family: $font_GlacialIndifference;
    }
  }

  > div {
    max-width: 1200px;
    margin: 0 auto 5rem;
    padding: 0 5rem;
  }

  @media screen and (max-width: 1199.98px) {
    /* 데스크탑 */
  }

  @media screen and (max-width: 991.98px) {
    /* 타블렛 */

    > div {
      margin: 0 1rem 5rem 1rem;
    }
  }

  @media screen and (max-width: 767.98px) {
    .privacy-main{
      margin-top: 1rem;
    }
    /* 스마트폰 가로 */

    > div {
      margin: 0 0 5rem 0;
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 575.98px) {
    /* 스마트폰 세로 */
  }
}
