@font-face {
  font-family: "Alata";
  src: url("./fonts/Alata-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GlacialIndifference";
  src: url("./fonts/GlacialIndifference-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "GlacialIndifference";
  src: url("./fonts/GlacialIndifference-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Kollektif";
  src: url("./fonts/Kollektif.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Kollektif";
  src: url("./fonts/Kollektif-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Garet";
  src: url("./fonts/Garet-Book.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Garet";
  src: url("./fonts/Garet-Heavy.ttf") format("truetype");
  font-weight: bold;
}

$legend: calc(1.275rem + 0.3vw);
$h1: calc(1.375rem + 1.5vw);
$h2: calc(1.325rem + 0.9vw);
$h3: calc(1.3rem + 0.6vw);
$h4: calc(1.275rem + 0.3vw);
$h5: 1.225rem;
$display-1: calc(1.725rem + 5.7vw);
$display-2: calc(1.675rem + 5.1vw);
$display-3: calc(1.575rem + 3.9vw);
$display-4: calc(1.475rem + 2.7vw);
$close: calc(1.275rem + 0.3vw);

$font_Alata: "Alata", -apple-system, BlinkMacSystemFont, "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif;
$font_GlacialIndifference: "GlacialIndifference", -apple-system, BlinkMacSystemFont, "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif;
$font_Kollektif: "Kollektif", -apple-system, BlinkMacSystemFont, "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif;
$font_Garet: "Garet", -apple-system, BlinkMacSystemFont, "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif;

h1 {
  font-size: $h1;
  font-weight: bold;
}
h2 {
  font-size: $h2;
  font-weight: bold;
}
h3 {
  font-size: $h3;
  font-weight: bold;
}
h4 {
  font-size: $h4;
  font-weight: bold;
}
h5 {
  font-size: $h5;
  font-weight: bold;
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  text-decoration: none;
  color: #fff;
}
